.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.add-widget-button {
    margin-bottom: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
}

.widget-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 1200;
}

.widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
}

.search-bar {
    display: flex;
}

.search-button{
    height: 33px;
    padding: 0 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.save-button {
    width: 120px;
    padding: 8px 20px;
    background-color: #28a745;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.delete-button {
    width: 120px;
    padding: 8px 20px;
    background-color: red;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.add-url-button {
    width: 120px;
    padding: 8px 20px;
    background-color: royalblue;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px auto;
}

.urls {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;
}

.url-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.urls-bottom {
    margin-bottom: 0px;
}

.url-container-botom {
    margin-bottom: 0px;
}

.url-input-container {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-grow: 1;
    width: 100%;
}

.url-input-container input {
    margin-right: 10px; /* Espacio entre los inputs */
}

.input {
    flex-grow: 1;
    width: 100%;
}

.remove-url-button {
    border: none;
    background: none;
    color: red;
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.color-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 80%;
    text-align: center;
}

.color-button-container {
    display: flex;
    align-items: center;
}

.color-button {
    padding: 6px 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.color-preview {
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    color: #333;
}

.buttons-config {
    text-align: center;
    width: 100%;
}

.color-modal {
    text-align: center;
    align-content: center;
}

.color-modal button {
    margin-top: 10px;
    padding: 8px 20px;
    background-color: cornflowerblue;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.color-picker {
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.color-button {
    width: 70px;
}

.checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 50px;
  }
  
  .checkbox-label input[type="checkbox"] {
    margin-right: 5px;
    width: 20px;
    margin-top: 10px;
  }

  .label-email {
    margin-top: 50px;
  }
  