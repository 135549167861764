.styled-input {
  flex: 1;
  margin-right: 10px;
}

.styled-input {
  border: none;
  outline: none;
  padding: 4px 5px;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 15px;
  width: 100%;
  box-sizing: border-box;
  background-color: transparent;
}

.styled-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  background-color: lightgrey;
}

.styled-button-extra {
  width: 120px;
  height: 45px;
  color: #007bff;
  border-radius: 0px 15px 15px 0px;
}

.social {
  min-width: 150px;
}

.styled-button.copied {
  background-color: #28a745;
  color: white;
}

.urls {
  text-align: center;
  align-content: center;
}

.input-row {
  display: flex;
  align-items: center;
  margin: 10px auto;
  width: 95%;
  padding: 0 20px;
  text-align: center;
  border: 1px solid #ccc;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  background-color: white;
}

.link-to-config {
  color: #999;
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-top: 20px;
}

.buttons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 20px;
  margin: 10px auto;
  text-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.buttons-bottom {
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  .buttons {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }
}

@media (max-width: 480px) {
  .buttons {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }
}

.button-row {
  margin-right: 10px; /* Agrega un margen derecho a todos los botones */
}


.centered-content {
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10px 0px;
}

.link-container {
  text-align: left;
  padding: 0 20px;
  margin-bottom: 20px;
}

.link-container-bottom {
  margin-bottom: 0px;
}

.link-align {
  display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.link-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: grey;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ajusta la altura según tus necesidades */
}